import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import ContentsList from '../../../components/ContentsList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="コンテンツ - 原則" mdxType="SEO" />
    <PageTitle title="コンテンツ" enTitle="Contents" mdxType="PageTitle" />
    <p>{`コンテンツにとって重要なことは「何をどう伝えるか」です。`}</p>
    <p>{`このコンテンツガイドラインでは、主にAmebaとしてどう伝えるか、メッセージとしてどうあるべきかを解説しています。`}</p>
    <p>{`Amebaはブログのようなサービス上だけではなく、カスタマーサポートやセールス、メールマガジン、SNS、色々な接点でユーザーと言葉を交わします。
その接点ごとで、Amebaの話し方や伝え方に一貫性がないと、ユーザーは戸惑い、距離を置いてしまいます。
多くの人で作られたAmebaだからこその、その声や状況にあわせた話し方のトーンは`}<a parentName="p" {...{
        "href": "/principles/contents/brand-voice"
      }}>{`ブランドボイス`}</a>{`を元に、ひとりの人間のように振る舞うようにしてください。`}</p>
    <p>{`話し方だけでなく、使う言葉にも一貫性を持ってください。
サービス内で使われる用語や機能の表現にも気をつけてください。ユーザーとの対話において同じことについて話しているはずなのに、表現がずれてしまうと話がうまく通じず、フラストレーションを生んでしまいます。`}</p>
    <p>{`またちょっとした言い回しの違いであっても、ユーザーはそれを違和感として受け止めます。用語や日本語としての推奨する表現・表記については`}<a parentName="p" {...{
        "href": "/principles/contents/vocabulary"
      }}>{`用語・表記ルール`}</a>{`を参考にしてください。`}</p>
    <ContentsList list={[{
      url: "/principles/contents/brand-voice/",
      title: "ブランドボイス",
      enTitle: "Brand Voice",
      summary: "Amebaのサービスバリュー、ブランドパーソナリティからAmebaらしい声質やトーン、言葉選びをブランドボイスとして定義しています。"
    }, {
      url: "/principles/contents/vocabulary/",
      title: "用語・表記ルール",
      enTitle: "Vocabulary",
      summary: "一部のプロジェクトではtextlintを用いた機械的な表記チェックを試験しています。"
    }, {
      url: "/principles/contents/ui-labeling/",
      title: "UIラベリング",
      enTitle: "UI Labeling",
      summary: "UI内部で使用するラベリングの基本的なルールを定義しています。"
    }]} mdxType="ContentsList"></ContentsList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      